<template>
	<div class="row">
		<div class="col-12 col-xl-12">
			<div class="card">
				<div class="card-header">
					<div>
						<h4 class="card-header-title">Rides by User</h4>

						<small class="text-muted me-3"
							>Rides by user over the last 30 days</small
							>
					</div>
				</div>
				<div class="card-body">
					<bar-chart
						v-if="ridesByUsersLast30Days"
						class="chart barchart"
						:chart-data="ridesByUsersLast30Days"
						:options="barChartOptions"
						></bar-chart>
					<div
						v-if="loadingUserTripsChartData"
						class="d-flex justify-content-center align-items-center"
						>
						<div class="spinner-border" role="status"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BarChart from '@/components/charts/BarChart'
import { getYearlyBarChartData } from '@/utils/chart'

export default {
  name: 'UserRideHistory',
  components: { BarChart },
  props: {
    userId: {
      required: true,
      type: [Number, String]
    }
  },
  data () {
    return {
      loadingUserTripsChartData: false,
      errorLoadingUserTripsChartData: false,
      ridesByUsersLast30Days: null,
      barChartOptions: {
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              },
              offset: true
            }
          ],
          yAxes: [
            {
              gridLines: {
                borderDash: [8, 4],
                color: '#F6F6F7'
              },
              ticks: {
                // stepSize: 500,
              }
            }
          ]
        }
      }
    }
  },

  created () {
    this.fetchUserTripsChartData()
  },
  methods: {
    fetchUserTripsChartData () {
      this.loadingUserTripsChartData = true
      this.errorLoadingUserTripsChartData = false

      this.axios
        .get(`/v1/users/${this.userId}/trips/graph`)
        .then((res) => {
          this.ridesByUsersLast30Days = getYearlyBarChartData(res.data)
        })
        .catch(() => {
          this.errorLoadingUserTripsChartData = true
        })
        .finally(() => (this.loadingUserTripsChartData = false))
    }
  }

}
</script>

<style scoped>

</style>
